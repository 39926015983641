import React from "react";
import styles from "../RuleAccordion.module.scss";
import {RuleTable} from "../ruleTable/RuleTable";
import {parseGroupsToString} from "../../../../utils/util";
import {RuleManagerRule as Rule} from "@common/typing/";
import {useHistory} from "react-router-dom";
import {Button, Icon} from "@vacasa/react-components-lib";
import {Can} from "../../../../permissions/Can";
import {AbilityOptions} from "../../../../permissions/Ability";

interface AccordionItemProps {
    rule: Rule;
    deleteRuleValueFromRules: Function;
}

export const AccordionItem: React.FunctionComponent<AccordionItemProps> = (props) => {
    const {attributes: rule} = props.rule;
    const {deleteRuleValueFromRules} = props;
    const history = useHistory();

    const goToRuleValueForm = () => {
        history.push({
            pathname: `/rule/${props.rule.id}`,
            state: {rule: rule, value: null},
        });
    };

    const renderRuleDetailHeader = (): JSX.Element => {
        return (
            <div className={`alert mb-1 ${styles.alertMidnight}`} role="alert">
                <div className="row align-items-center">
                    <div className={`col-md-2 ${styles.verticalLine}`}>
                        <span className={styles.iconPadding}>
                            <Icon.Tag className="ml-5" />
                        </span>
                        <span className={styles.headerPadding}>Default Code</span>
                    </div>
                    <div className="col-md-2">Rule Description:</div>
                    <div className={`col-md-6 ${styles.smallFontSize} ${styles.verticalLine}`}>{rule.description}</div>
                    <Can I={AbilityOptions.Action.Update} a={rule.code}>
                        <div className="col-md-2 rule-button-container">
                            <Button variant="info" onClick={goToRuleValueForm}>
                                Add Value <Icon.PlusCircle />
                            </Button>
                        </div>
                    </Can>
                </div>
            </div>
        );
    };

    const renderEmptyValuesMessage = (): JSX.Element => {
        return (
            <div className={`alert mb-1 ${styles.alertMidnight}`} role="alert">
                The rule has no associated values.
            </div>
        );
    };

    const renderAccordionItemButton = (): JSX.Element => {
        return (
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${rule.id}`}
                aria-expanded="true"
                aria-controls={`collapse${rule.id}`}
            >
                <div className="row col-md-12">
                    <div className="col-md-2 d-flex">{rule.code}</div>
                    <div className="col-md-5 d-flex">{rule.name}</div>
                    <div className="col-md-2 d-flex">{rule.guest_display ? "Display" : "Hide"}</div>
                    <div className="col-md-3 d-flex">{parseGroupsToString(rule.groups)}</div>
                </div>
            </button>
        );
    };

    return (
        <div className="accordion-item" key={rule.id}>
            <h2 className="accordion-header" id={`heading${rule.id}`}>
                {renderAccordionItemButton()}
            </h2>
            <div
                id={`collapse${rule.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${rule.id}`}
                data-bs-parent={`#accordionRule`}
            >
                <div className={`accordion-body pt-1 ${styles.accordionBodyPadding}`}>
                    {renderRuleDetailHeader()}
                    {rule.values && rule.values.length > 0 ? (
                        <RuleTable rule={props.rule} deleteRuleValueFromRules={deleteRuleValueFromRules} />
                    ) : (
                        renderEmptyValuesMessage()
                    )}
                </div>
            </div>
        </div>
    );
};
