import React from "react";
import {ThemeProvider} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import RouterApp from "./router/Router";
import {Toaster} from "react-hot-toast";
import {AbilityContext, Can} from "./permissions/Can";
import ability, {AbilityOptions} from "./permissions/Ability";
import "./App.css";
import * as _ from "lodash";

function App() {
    return (
        <AbilityContext.Provider value={ability}>
            <ThemeProvider theme={_default}>
                <Toaster position="top-right" reverseOrder={false} />
                <div className="admin-app">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="container-custom mt-5">
                                <div className="container ps-0">
                                    <h2>Rule Manager</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="container-custom">
                                <div className="container my-3 p-3 mt-0 bg-body rounded">
                                    <div className="ps-3 pe-3">
                                        <RouterApp />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </AbilityContext.Provider>
    );
}

export default App;
