import _ from "lodash";

export function paginate(items: any[], pageNumber: number, pageSize: number): Array<any> {
    const startIndex = (pageNumber - 1) * pageSize;
    return _(items).slice(startIndex).take(pageSize).value();
}

export function parseGroupsToString(groups): string {
    if (groups && groups.length > 0) {
        return groups.map((g) => g.name).join(",");
    }

    return "None";
}
