import React, {useState, useEffect, useContext} from "react";
import styles from "./RuleIndex.module.scss";
import {UnitsService} from "@common/units-api";
import {authManager} from "@common/authentication";
import {RuleAccordion} from "./rule/ruleAccordion/RuleAccordion";
import {paginate} from "../utils/util";
import {Pagination} from "./pagination/Pagination";
import {RuleManagerRule as Rule, RuleGroup} from "@common/typing";
import {RotatingLines} from "react-loader-spinner";
import toast from "react-hot-toast";
import _ from "lodash";
import {AbilityContext} from "../permissions/Can";
import {updateAbility} from "../permissions/Ability";

export const RuleIndex: React.FunctionComponent<any> = (props) => {
    const token = authManager.getJwt();
    const unitService = new UnitsService(token);

    const [rules, setRules] = useState(Array<Rule>());
    const [isLoading, setIsLoading] = useState(false);
    const [ruleGroups, setRuleGroups] = useState(Array<RuleGroup>());
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [rulesSortOrder, setRulesSortOrder] = useState({attribute: "code", type: "asc"});
    const [searchInputs, setSearchInputs] = useState({
        code: "",
        name: "",
        visibility: "",
        group: "",
    });

    const ability = useContext(AbilityContext);

    /** Load init data for application */
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const rules = filterByPermissions(await unitService.getRules());
                const ruleGroups = await unitService.getRuleGroups();

                setRules(rules);
                setRuleGroups(ruleGroups);
            } catch (e) {
                toast.error("An error occurred while loading the data.");
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    /** Resize iframe for each render */
    useEffect(() => {
        resizeContent();
    });

    const filterByPermissions = (rules: Rule[]): Rule[] => {
        const userInfo = authManager.getInfoFromAdmin<any>();
        const rulesFiltered = rules.filter((rule) => {
            const hasPermission = rule.attributes.groups.some((group) => _.get(userInfo, group.permission_slug, false) === true);
            if (hasPermission) return rule;
        });

        updateAbility(ability, rulesFiltered, userInfo);

        return rulesFiltered;
    };

    const deleteRuleValueFromRules = (ruleId, valueId) => {
        const previousRules = _.cloneDeep(rules);

        const ruleToUpdate = previousRules.find((r) => r.id === ruleId);
        ruleToUpdate.attributes["values"] = ruleToUpdate.attributes["values"].filter((v) => v.id !== valueId);

        setRules([...previousRules]);
    };

    /** Pagination and filtering method for rules */
    const getPageData = (): {totalCount: number; filteredRules: Array<Rule>} => {
        let filtered = _.orderBy(rules, (r) => r.attributes[rulesSortOrder.attribute], rulesSortOrder.type as "desc" | "asc");
        if (!isSearchInputEmpty()) {
            filtered = filterRules(filtered);
        }

        const filteredAndPaginatedRules = paginate(filtered, currentPage, pageSize);
        return {totalCount: filtered.length, filteredRules: filteredAndPaginatedRules};
    };

    const isSearchInputEmpty = (): boolean => {
        const {code, name, group, visibility} = searchInputs;
        return !(code !== "" || name !== "" || group !== "" || visibility !== "");
    };

    const filterRules = (rulesSorted: Array<Rule>): Array<Rule> => {
        let filtered = rulesSorted;
        for (const [key, value] of Object.entries(searchInputs)) {
            if (value !== "") {
                filtered = filterRule(filtered, key, value);
            }
        }

        return filtered;
    };

    const filterRule = (rules: Array<Rule>, key: string, value: any): Array<Rule> => {
        let filtered = rules;
        switch (key) {
            case "visibility":
                const visibilityFilter = value === "display";
                return filtered.filter((r) => r.attributes.guest_display === visibilityFilter);

            case "group":
                return filtered.filter((r) => r.attributes.groups.some((g) => g.id == value));

            default:
                return filtered.filter((r) => r.attributes[key].toLowerCase().includes(value.toLowerCase()));
        }
    };

    const handleInputChange = ({currentTarget: input}): void => {
        const inputs = {...searchInputs};

        inputs[input.name] = input.value;

        setSearchInputs({...inputs});
        setCurrentPage(1);
    };

    const resizeContent = (): void => {
        const height = 800; //set 800px of height
        window.parent.postMessage({action: `${height}px`, type: "resize"}, "*");
    };

    const handleSortChange = (attribute: string): void => {
        if (attribute === rulesSortOrder.attribute) {
            const orderType = rulesSortOrder.type === "desc" ? "asc" : "desc";
            setRulesSortOrder({...rulesSortOrder, type: orderType});
        } else {
            setRulesSortOrder({attribute: attribute, type: "asc"});
        }
    };

    const {totalCount, filteredRules} = getPageData();
    return (
        <React.Fragment>
            <h5 className="pb-0">Rules</h5>
            <hr className={styles.headerSeparator} />
            {isLoading ? (
                <div className={`d-flex justify-content-center ${styles.loader}`}>
                    <RotatingLines width="50" strokeColor="#1a475b" />
                </div>
            ) : (
                <React.Fragment>
                    <RuleAccordion
                        rules={filteredRules}
                        handleInputChange={handleInputChange}
                        searchInputs={searchInputs}
                        deleteRuleValueFromRules={deleteRuleValueFromRules}
                        ruleGroups={ruleGroups}
                        handleSortChange={handleSortChange}
                        rulesSortOrder={rulesSortOrder}
                    />
                    <Pagination itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} onPageChange={setCurrentPage} />
                </React.Fragment>
            )}

            <div className={styles.appFooter}></div>
        </React.Fragment>
    );
};
