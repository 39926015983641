import {AbilityBuilder, defineAbility, Ability} from "@casl/ability";
import {RuleManagerRule} from "@common/typing";
import _ from "lodash";

export const AbilityOptions = {
    Action: {
        Read: "read",
        Update: "update",
        Create: "create",
    },
    Entity: {
        GeneralRule: "GeneralRule",
    },
};

export default defineAbility((can) => {});

export function updateAbility(ability, unitRules: RuleManagerRule[], userInfo: object) {
    const {can, rules} = new AbilityBuilder(Ability);
    for (const rule of unitRules) {
        const rulePermissions = rule?.attributes?.groups.map((group) => group.permission_slug);
        for (const permission of rulePermissions) {
            if (_.get(userInfo, permission, false) === true) {
                if (permission === "viewPermission") {
                    can(AbilityOptions.Action.Read, rule?.attributes?.code);
                }
                if (permission === "editPermission") {
                    can(AbilityOptions.Action.Update, rule?.attributes?.code);
                }
                if (permission === "cxBannerRule") {
                    can(AbilityOptions.Action.Read, rule?.attributes?.code);
                    can(AbilityOptions.Action.Update, rule?.attributes?.code);
                }
            }
        }
    }

    if (_.get(userInfo, "viewPermission", false) === true) {
        can(AbilityOptions.Action.Read, AbilityOptions.Entity.GeneralRule);
    }

    ability.update(rules);
}
