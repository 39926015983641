import React, {useEffect, useState} from "react";
import styles from "./RuleValueForm.module.scss";
import {authManager} from "@common/authentication";
import {UnitsService} from "@common/units-api/lib/src/units.service";
import {RuleManagerValue as RuleValue} from "@common/typing/";
import {Button} from "@vacasa/react-components-lib";
import {useLocation, useHistory} from "react-router-dom";
import {Icon} from "@vacasa/react-components-lib";
import {RotatingLines} from "react-loader-spinner";
import {CSVLink} from "react-csv";
import toast from "react-hot-toast";
import * as _ from "lodash";
import {Can} from "../../../permissions/Can";
import {AbilityOptions} from "../../../permissions/Ability";

interface RuleFormProps {
    match: any;
}

interface RuleValueForm {
    valueCode: string;
    visibility: boolean;
    generalDescription: string;
    attributes: any;
}

interface RuleValueAttributeSchema {
    required?: Array<string>;
    properties?: any;
}

interface LocationState {
    rule: any;
    value: any;
}

export const RuleValueForm: React.FunctionComponent<RuleFormProps> = (props) => {
    const token = authManager.getJwt();
    const unitService = new UnitsService(token);

    const {state} = useLocation<LocationState>();
    const history = useHistory();
    const attributesSchema = _.get(state, "rule.value_attributes_validation", null);
    const isNewRuleValueForm = props.match.params.valueId === undefined;

    const [isFormValid, setIfFormValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [ruleValueUnits, setRuleValueUnits] = useState([]);
    const [isUnitDataLoading, setIsUnitDataLoading] = useState(false);
    const [value, setValue] = useState({
        valueCode: "",
        visibility: false,
        generalDescription: "",
        attributes: null,
    } as RuleValueForm);

    /** Set ruleValue from state and fetch units */
    useEffect(() => {
        (async () => {
            validateStateData();

            const ruleValue = _.get(state, "value", null);
            initFormData(ruleValue, attributesSchema);

            await fetchRuleValueUnits();
        })();
    }, []);

    /** Validate form every time it has been updated */
    useEffect(() => {
        validateForm();
    }, [value]);

    /** Update form submit button state when form is updated */
    useEffect(() => {
        if (isFormValid) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [isFormValid]);

    /** Load RuleValueUnits (Unit Assigned) */
    const fetchRuleValueUnits = async (): Promise<void> => {
        try {
            setIsUnitDataLoading(true);
            if (!isNewRuleValueForm) {
                let units = await unitService.getUnitRuleValuesByRuleAndValueCode(state.rule.code, state.value.code);
                units = units.filter((u) => u.attributes.rule_id == state.rule.id).map((u) => u.attributes.legacy_unit_id);
                setRuleValueUnits(units);
            }
        } catch (e) {
            toast.error("An error occurred while loading Unit Assigned.");
        } finally {
            setIsUnitDataLoading(false);
        }
    };

    /** Map rule values attributes to labels */
    const mapKeyToLabel = (key: string): string => {
        switch (key) {
            case "days_till_checkin":
                return "Days till check-in";
            case "percent_of_rent":
                return "Refund amount % of rent";
            case "percent_of_booking_fee":
                return "Refund amount % of booking fee";
            case "override_notes":
                return "Override notes";
            case "override_end_date":
                return "Override end date";
            case "number_permits_req":
                return "Number of permits required";
            case "override_requirement":
                return "Override requirement";
            case "override_effective_date":
                return "Override effective date";
            case "copy":
                return "Guest description";

            default:
                return key;
        }
    };

    /** Validate navigation data exist */
    const validateStateData = (): void => {
        const rule = _.get(state, "rule", null);

        if (_.isNil(rule)) {
            history.goBack();
        }
    };

    /** Validate form state */
    const validateForm = (): void => {
        let isValid = true;

        if (_.isEmpty(value.valueCode)) {
            isValid = false;
        }

        if (_.isEmpty(value.generalDescription)) {
            isValid = false;
        }

        const requiredAttributes = _.get(attributesSchema, "required", []);
        if (!_.isNil(value.attributes)) {
            for (let requiredAttribute of requiredAttributes) {
                if (value.attributes[requiredAttribute] === "") {
                    isValid = false;
                }
            }
        }

        setIfFormValid(isValid);
    };

    /** Get default attribute value depending of the type */
    const getAttributeDefaultValue = (existingValue: any, type: string): any => {
        // For number and string the default value is "", the only exception is boolean
        if (type === "boolean" && existingValue === "") {
            return false;
        }

        return existingValue;
    };

    /** Set ruleValue from state and populate attributes from attributesSchema */
    const initFormData = (ruleValue: RuleValue, attributesSchema: RuleValueAttributeSchema): void => {
        let attributes = {};
        if (!_.isNil(attributesSchema)) {
            for (let key of Object.keys(attributesSchema.properties)) {
                const existingValue = _.get(ruleValue, `rule_value_attributes.${key}`, "");
                attributes[key] = getAttributeDefaultValue(existingValue, attributesSchema.properties[key].type);
            }
        }

        const existingValue = {
            valueCode: _.get(ruleValue, "code", ""),
            visibility: _.get(ruleValue, "hide", false),
            generalDescription: _.get(ruleValue, "description", ""),
            attributes: _.isEmpty(attributes) ? null : attributes,
        };

        setValue({...existingValue});
    };

    const handleInputChange = ({currentTarget: input}): void => {
        const inputs = {...value};

        inputs[input.name] = input.type === "checkbox" ? !inputs[input.name] : input.value;

        setValue({...inputs});
    };

    const handleCustomInputChange = ({currentTarget: input}): void => {
        const inputs = {...value};
        if (!isCustomInputValid(input)) {
            return;
        }

        inputs.attributes[input.name] = input.type === "checkbox" ? !inputs.attributes[input.name] : input.value;

        setValue({...inputs});
    };

    const isCustomInputValid = (input): boolean => {
        if (input.type === "number" && input.value !== "") {
            if (+input.value > +input.max || +input.value < +input.min) {
                return false;
            }
        }

        return true;
    };

    const handleCreationForm = async (user: string): Promise<void> => {
        if (attributesSchema !== null) {
            for (const [key, val] of Object.entries(value.attributes)) {
                if (val === "") {
                    delete value.attributes[key];
                    continue;
                }

                const type = _.get(attributesSchema, `properties[${key}].type`, "");
                value.attributes[key] = type === "number" ? +value.attributes[key] : value.attributes[key];
            }
        }

        const data = {
            data: {
                type: "rule_value",
                attributes: {
                    rule_id: props.match.params.ruleId,
                    code: value.valueCode,
                    description: value.generalDescription,
                    hide: value.visibility,
                    rule_value_attributes: {...value.attributes},
                    done_by: user,
                },
            },
        };

        await unitService.postRuleValue(data);
    };

    const handleUpdateForm = async (user: string): Promise<void> => {
        if (attributesSchema !== null) {
            for (const [key, val] of Object.entries(attributesSchema.properties)) {
                value.attributes[key] = val["type"] === "number" ? +value.attributes[key] : value.attributes[key];
            }
        }

        const data = {
            data: {
                type: "rule_value",
                attributes: {
                    rule_id: props.match.params.ruleId,
                    code: value.valueCode,
                    description: value.generalDescription,
                    hide: value.visibility,
                    rule_value_attributes: {...value.attributes},
                    done_by: user,
                },
            },
        };

        const {valueId} = props.match.params;
        await unitService.patchRuleValue(valueId, data);
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            setIsLoading(true);
            setIsDisabled(true);

            const info = authManager.getInfoFromAdmin();
            if (isNewRuleValueForm) {
                await handleCreationForm(info["user"]);
            } else {
                await handleUpdateForm(info["user"]);
            }

            toast.success("Rule value added successfully!");
            history.goBack();
        } catch (e) {
            toast.error("An error occurred while storing the data.");
        } finally {
            setIsLoading(false);
            setIsDisabled(false);
        }
    };

    const getTitle = (): string => {
        return isNewRuleValueForm ? `${state.rule.name} / New value` : `${state.rule.name} / ${state.value.code} (Edit mode)`;
    };

    const renderAttributes = (editable: boolean = false): Array<JSX.Element> => {
        const components = [];
        if (attributesSchema !== null) {
            for (const [key, value] of Object.entries(attributesSchema.properties)) {
                let fieldType = _.get(value, "type", "");
                fieldType = value.hasOwnProperty("enum") ? "select" : fieldType;
                const requiredAttributes = _.get(attributesSchema, `required`, null);
                const isRequired = requiredAttributes ? requiredAttributes.includes(key) : false;
                switch (fieldType) {
                    case "number":
                        components.push(renderNumber(key, value["minimum"], value["maximum"], isRequired, editable));
                        break;

                    case "boolean":
                        components.push(renderBoolean(key, isRequired));
                        break;

                    case "select":
                        components.push(renderSelect(key, value["enum"], isRequired, editable));
                        break;

                    default:
                        // Edge case for copy attribute
                        if (key === "copy") {
                            components.push(renderTextArea(key, isRequired, editable));
                            break;
                        }

                        components.push(renderTextInput(key, isRequired, editable));
                        break;
                }
            }
        }

        return components;
    };

    const renderSelect = (name: string, values: Array<string>, isRequired: boolean, editable: boolean): JSX.Element => {
        return (
            <div className="col-md-6 px-5 pb-3 pt-3" key={name}>
                <div className={`form-group ${isRequired ? "required" : ""}`}>
                    <label htmlFor={name}>{mapKeyToLabel(name)}</label>
                    <select
                        id={name}
                        name={name}
                        className="form-select"
                        value={value.attributes[name]}
                        onChange={handleCustomInputChange}
                        disabled={!editable}
                    >
                        <option value=""></option>
                        {values.map((d) => (
                            <option key={d} value={d}>
                                {d}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    const renderTextInput = (name: string, isRequired: boolean, editable: boolean): JSX.Element => {
        return (
            <div className="col-md-6 px-5 pb-3 pt-3" key={name}>
                <div className={`form-group ${isRequired ? "required" : ""}`}>
                    <label htmlFor={name}>{mapKeyToLabel(name)}</label>
                    <input
                        type="text"
                        className="form-control"
                        id={name}
                        name={name}
                        value={value.attributes[name]}
                        onChange={handleCustomInputChange}
                        disabled={!editable}
                    />
                </div>
            </div>
        );
    };

    const renderTextArea = (name: string, isRequired: boolean, editable: boolean): JSX.Element => {
        return (
            <div className="col-md-6 px-5 pb-3 pt-3" key={name}>
                <div className={`form-group ${isRequired ? "required" : ""}`}>
                    <label htmlFor={name}>{mapKeyToLabel(name)}</label>
                    <textarea
                        className="form-control"
                        id={name}
                        name={name}
                        rows={3}
                        value={value.attributes[name]}
                        onChange={handleCustomInputChange}
                        disabled={!editable}
                    ></textarea>
                </div>
            </div>
        );
    };

    const renderNumber = (name: string, min: string, max: string, isRequired: boolean, editable: boolean) => {
        return (
            <div className="col-md-6 px-5 pb-3 pt-3" key={name}>
                <div className={`form-group ${isRequired ? "required" : ""}`}>
                    <label htmlFor={name}>{mapKeyToLabel(name)}</label>
                    <input
                        type="number"
                        className="form-control"
                        id={name}
                        name={name}
                        value={value.attributes[name]}
                        onChange={handleCustomInputChange}
                        min={min}
                        max={max}
                        disabled={!editable}
                    />
                </div>
            </div>
        );
    };

    const renderBoolean = (name: string, isRequired: boolean) => {
        return (
            <div className="col-md-6 px-5 pb-3 pt-3" key={name}>
                <div className="form-group">
                    <label htmlFor={name}>{mapKeyToLabel(name)}</label>
                    <div className={`form-check mt-1 ${styles.switchPadding}`}>
                        <div className="row">
                            <div className={`col ${isRequired ? "required" : ""}`}>
                                <label className={styles.switch}>
                                    <input id={name} name={name} value={value.attributes[name]} onChange={handleCustomInputChange} type="checkbox" />
                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getCsvData = (): Array<Array<string>> => {
        const unitIdList = [["Unit ID"]];
        ruleValueUnits.map((id) => unitIdList.push([id]));
        return unitIdList;
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6 d-flex flex-column justify-content-center ">
                    <div className="row">
                        <div className={`col-md-1 pe-0 ${styles.topLeftPanel}`}>
                            <div className={styles.backIconContainer}>
                                <Icon.CornerDownLeft
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col ps-0">
                            <label className="pb-2 col-form-label">{getTitle()}</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row justify-content-end">
                        <div className={`col-md-3 ${styles.topPanel}`}>
                            <Button
                                variant="info"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className={`col-md-3 ${styles.topPanel}`}>
                            <Can I={AbilityOptions.Action.Update} a={state.rule.code}>
                                <Button variant="secondary" onClick={handleSubmit} disabled={isDisabled}>
                                    {isLoading ? <RotatingLines strokeColor="white" /> : "Save"}
                                </Button>
                            </Can>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`my-3 p-3 bg-body rounded ${styles.borderMidnight}`}>
                <div className="row">
                    <div className="col-9">
                        <div className="row mb-2">
                            <div className="col-md-6 px-5 pb-3 pt-3">
                                <div className="form-group required">
                                    <Can I={AbilityOptions.Action.Update} a={state.rule.code} passThrough>
                                        {(allowed) => (
                                            <React.Fragment>
                                                <label htmlFor="valueCode ">Value code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="valueCode"
                                                    name="valueCode"
                                                    value={value.valueCode}
                                                    onChange={handleInputChange}
                                                    disabled={!allowed}
                                                />
                                            </React.Fragment>
                                        )}
                                    </Can>
                                </div>
                            </div>
                            <div className="col-md-6 px-5 pb-3 pt-3">
                                <div className="form-group">
                                    <div className="required">
                                        <label htmlFor="visibility">Visibility</label>
                                    </div>
                                    <div className={`form-check mt-1 ${styles.switchPadding}`}>
                                        <div className="row">
                                            <div className="col-2">
                                                <label className="pt-1">{value.visibility ? "Hide" : "Display"}</label>
                                            </div>
                                            <div className="col">
                                                <label className={styles.switch}>
                                                    <Can I={AbilityOptions.Action.Update} a={state.rule.code} passThrough>
                                                        {(allowed) => (
                                                            <input
                                                                id="visibility"
                                                                name="visibility"
                                                                type="checkbox"
                                                                checked={value.visibility}
                                                                onChange={handleInputChange}
                                                                disabled={!allowed}
                                                            />
                                                        )}
                                                    </Can>
                                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {value.attributes !== null && (
                                <Can I={AbilityOptions.Action.Update} a={state.rule.code} passThrough>
                                    {(allowed) => renderAttributes(allowed)}
                                </Can>
                            )}
                            <div className="col-md-6 px-5 pb-3 pt-3">
                                <div className="form-group required">
                                    <Can I={AbilityOptions.Action.Update} a={state.rule.code} passThrough>
                                        {(allowed) => (
                                            <React.Fragment>
                                                <label htmlFor="generalDescription">General description</label>
                                                <textarea
                                                    className="form-control"
                                                    id="generalDescription"
                                                    name="generalDescription"
                                                    rows={3}
                                                    value={value.generalDescription}
                                                    onChange={handleInputChange}
                                                    disabled={!allowed}
                                                ></textarea>
                                            </React.Fragment>
                                        )}
                                    </Can>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-3 ${styles.unitAssignedPanel}`}>
                        <label>Unit assigned</label>
                        <hr />
                        {isUnitDataLoading ? <RotatingLines /> : <React.Fragment />}
                        <p className={styles.unitList}>{ruleValueUnits.length > 0 ? ruleValueUnits.slice(0, 30).join(" ,") : ""}</p>

                        <p>
                            {!isNewRuleValueForm &&
                                !isUnitDataLoading &&
                                (ruleValueUnits.length > 0 ? (
                                    <React.Fragment>
                                        <small>{`(Showing ${ruleValueUnits.length > 30 ? 30 : ruleValueUnits.length} of ${
                                            ruleValueUnits.length
                                        })`}</small>
                                        <div></div>
                                        <CSVLink data={getCsvData()} filename={`${state.rule.code}-${state.value.code}-units.csv`}>
                                            <Button customClass="mt-5" variant="info">
                                                Download assigned Units <Icon.Download />
                                            </Button>
                                        </CSVLink>
                                    </React.Fragment>
                                ) : (
                                    <small>There are no units associated.</small>
                                ))}
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
