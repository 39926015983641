import React from "react";
import styles from "./RuleAccordion.module.scss";
import {RuleManagerRule as Rule, RuleGroup} from "@common/typing/";
import {AccordionItem} from "./AccordionItem/AccordionItem";
import {Icon} from "@vacasa/react-components-lib";

interface RuleAccordionProps {
    rules: Array<Rule>;
    handleInputChange: Function;
    searchInputs: {
        code: string;
        name: string;
        visibility: string;
        group: string;
    };
    ruleGroups: Array<RuleGroup>;
    deleteRuleValueFromRules: Function;
    handleSortChange: Function;
    rulesSortOrder: {attribute: string; type: string};
}

export const RuleAccordion: React.FunctionComponent<RuleAccordionProps> = (props) => {
    const {rules, handleInputChange, searchInputs, ruleGroups, deleteRuleValueFromRules, handleSortChange, rulesSortOrder} = props;

    const onInputChange = (e): void => {
        handleInputChange(e);
    };

    const renderArrowUp = (attribute: string, isActive: boolean): JSX.Element => {
        return (
            <Icon.ArrowUp
                className={isActive ? styles.sortIcon : ""}
                onClick={() => {
                    handleSortChange(attribute);
                }}
            />
        );
    };

    const renderArrowDown = (attribute: string, isActive: boolean): JSX.Element => {
        return (
            <Icon.ArrowDown
                className={isActive ? styles.sortIcon : ""}
                onClick={() => {
                    handleSortChange(attribute);
                }}
            />
        );
    };

    const renderArrow = (attribute: string): JSX.Element => {
        const isActive = attribute === rulesSortOrder.attribute;
        if (!isActive || rulesSortOrder.type === "desc") {
            return renderArrowDown(attribute, isActive);
        }

        return renderArrowUp(attribute, isActive);
    };

    return (
        <React.Fragment>
            <div className="row mb-2">
                <div className={`col-md-2 d-flex justify-content-center ${styles.ruleHeader}`}>
                    {renderArrow("code")}
                    Code
                </div>
                <div className={`col-md-5 d-flex justify-content-center ${styles.ruleHeader}`}>
                    {renderArrow("name")}
                    Rule Name
                </div>
                <div className={`col-md-2 d-flex justify-content-center ${styles.ruleHeader}`}>
                    {renderArrow("guest_display")}
                    Visibility
                </div>
                <div className={`col-md-3 d-flex justify-content-center ${styles.ruleHeader}`}>Group / Category</div>
            </div>
            <div className="row">
                <div className="col-md-2 d-flex justify-content-center">
                    <input type="text" className="form-control" id="code" name="code" onChange={onInputChange} value={searchInputs.code} />
                </div>
                <div className="col-md-5 d-flex justify-content-center">
                    <input type="text" className="form-control" id="name" name="name" onChange={onInputChange} value={searchInputs.name} />
                </div>
                <div className="col-md-2 d-flex justify-content-center">
                    <select name="visibility" className="form-select" onChange={onInputChange} value={searchInputs.visibility}>
                        <option value="">All</option>
                        <option value="display">Display</option>
                        <option value="hide">Hide</option>
                    </select>
                </div>
                <div className="col-md-3 d-flex justify-content-center">
                    <select name="group" className="form-select" onChange={onInputChange} value={searchInputs.group}>
                        <option value="">All</option>
                        {ruleGroups.map((rg) => (
                            <option key={rg.id} value={rg.id}>
                                {rg.attributes.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="accordion mt-4" id="accordionRule">
                {rules.map((rule, key) => {
                    return <AccordionItem rule={rule} key={key} deleteRuleValueFromRules={deleteRuleValueFromRules} />;
                })}
            </div>
        </React.Fragment>
    );
};
