import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {RuleIndex} from "../components/RuleIndex";
import {RuleValueForm} from "../components/rule/ruleValueForm/RuleValueForm";

class RouterApp extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/rule/:ruleId/:valueId?" component={RuleValueForm} />
                    <Route path="/" component={RuleIndex} />
                </Switch>
            </Router>
        );
    }
}

export default RouterApp;
