import React, {useState, useEffect} from "react";
import {Button, Icon, Modal} from "@vacasa/react-components-lib";
import styles from "./RuleTable.module.scss";
import {authManager} from "@common/authentication/lib";
import {UnitsService} from "@common/units-api/lib/src/units.service";
import {RuleManagerRule as Rule} from "@common/typing/";
import {useHistory} from "react-router-dom";
import {RotatingLines} from "react-loader-spinner";
import toast from "react-hot-toast";
import moment from "moment";
import _ from "lodash";
import {Can} from "../../../../permissions/Can";
import {AbilityOptions} from "../../../../permissions/Ability";

interface RuleTableProps {
    rule: Rule;
    deleteRuleValueFromRules: Function;
}

export const RuleTable: React.FunctionComponent<RuleTableProps> = (props) => {
    const history = useHistory();
    const token = authManager.getJwt();
    const unitService = new UnitsService(token);
    const permissions = authManager.getInfoFromAdmin();

    const {values: ruleValues} = props.rule.attributes;
    const {deleteRuleValueFromRules} = props;

    const [showModal, setShowModal] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (!showModal) {
            setErrorMessage("");
        }
    }, [showModal]);

    const handleOnDelete = async (): Promise<void> => {
        try {
            setErrorMessage("");
            setIsDeleteButtonDisabled(true);
            setIsDeleteLoading(true);
            await unitService.deleteRuleValue(selectedValue);
            setIsDeleteButtonDisabled(false);
            setIsDeleteLoading(false);
            setShowModal(false);
            deleteRuleValueFromRules(props.rule.id, selectedValue);
            toast.success("The rule value was deleted successfully");
        } catch (e) {
            console.error(e);
            const error = _.get(e, "response.data[0].detail.error", null);
            setErrorMessage(_.isNil(error) ? "An unhandled error has occurred." : error);
            setIsDeleteButtonDisabled(false);
            setIsDeleteLoading(false);
        }
    };

    const handleModalChange = (valueId: number): void => {
        setSelectedValue(valueId);
        setShowModal(true);
    };

    const goToRuleValueForm = (valueId) => {
        const {attributes: rule} = props.rule;
        const value = rule.values.find((v) => v.id === valueId);

        history.push({
            pathname: `/rule/${rule.id}/${valueId}`,
            state: {rule: rule, value: value},
        });
    };

    const renderTableRow = (value): JSX.Element => {
        return (
            <tr key={value.code}>
                <th className="align-content-lg-end" scope="row">
                    {value.code === props.rule.attributes.default_value_code ? <Icon.Tag /> : <React.Fragment />}
                </th>
                <td>{value.code}</td>
                <td>{value.hide ? "Hide" : "Display"}</td>
                <td>{value.modified_by ? value.modified_by : value.created_by}</td>
                <td>{moment.utc(value.modified_at ? value.modified_at : value.created_at).format("MM/DD/YYYY HH:mm")}</td>
                <td>
                    <Can I={AbilityOptions.Action.Update} a={props.rule.attributes.code}>
                        <React.Fragment>
                            <button type="button" className={styles.actionButtons} onClick={() => goToRuleValueForm(value.id)}>
                                <Icon.Edit />
                            </button>
                            |
                            <button type="button" className={styles.actionButtons}>
                                <Icon.Trash2 onClick={() => handleModalChange(value.id)} />
                            </button>
                        </React.Fragment>
                    </Can>
                    <Can not I={AbilityOptions.Action.Update} a={props.rule.attributes.code}>
                        <button type="button" className={styles.actionButtons} onClick={() => goToRuleValueForm(value.id)}>
                            <Icon.Search />
                        </button>
                    </Can>
                </td>
            </tr>
        );
    };

    const renderModal = (): JSX.Element => {
        return (
            <Modal showModal={showModal} setShowModal={setShowModal} size="medium">
                <div>
                    <h1>Delete value</h1>
                    <hr className="mb-5" />
                    <p className="mb-5">Are you sure you want to delete the value?</p>
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    <div className="row">
                        <div className={`col-md-6 ${styles.modalButton}`}>
                            <Button variant="secondary" onClick={() => handleOnDelete()} disabled={isDeleteButtonDisabled}>
                                {isDeleteLoading ? <RotatingLines strokeColor="white" /> : "Confirm"}
                            </Button>
                        </div>
                        <div className={`col-md-6 ${styles.modalButton}`}>
                            <Button variant="info" onClick={() => setShowModal(!showModal)}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const renderTableHeader = (): JSX.Element => {
        return (
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" className={styles.lightHeader}>
                        Value code
                    </th>
                    <th scope="col" className={styles.lightHeader}>
                        Visibility
                    </th>
                    <th scope="col" className={styles.lightHeader}>
                        Created by
                    </th>
                    <th scope="col" className={styles.lightHeader}>
                        Last Update
                    </th>
                    <th scope="col" className={styles.lightHeader}>
                        Action
                    </th>
                </tr>
            </thead>
        );
    };

    return (
        <React.Fragment>
            <table className={`table ${styles.tableMidnight} ${styles.roundedTable}`}>
                {renderTableHeader()}
                <tbody>
                    {ruleValues.map((value) => {
                        return renderTableRow(value);
                    })}
                </tbody>
            </table>
            <div className={`alert ${styles.alertMidnight} ${styles.tableFooter}`} role="alert">
                <div className="row">
                    <div className="col offset-md-4 text-end">
                        <b>Created by:</b> {props.rule.attributes.created_by}
                    </div>
                    <div className="col text-end">
                        <b>Last updated:</b>{" "}
                        {moment
                            .utc(props.rule.attributes.modified_at ? props.rule.attributes.modified_at : props.rule.attributes.created_at)
                            .format("MM/DD/YYYY HH:mm")}
                    </div>
                </div>
            </div>
            {renderModal()}
        </React.Fragment>
    );
};
