import React from "react";
import * as _ from "lodash";

export const Pagination: React.FunctionComponent<{
    itemsCount: number;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}> = ({itemsCount, pageSize, currentPage, onPageChange}) => {
    const pagesCount = Math.ceil(itemsCount / pageSize);

    if (pagesCount === 1) return null;

    const pages = _.range(1, pagesCount + 1);

    return (
        <nav className="mt-3 float-end" aria-label="pagination">
            <ul className="pagination">
                {pages.map((page) => (
                    <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
                        <a className="page-link clickable" onClick={() => onPageChange(page)}>
                            {page}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
